<template>
    <v-container v-if="$store.state.user">
        <v-row class="text-center mt-10">
            <v-col class="mb-2">
                <h1 class="display-2 font-weight-bold mb-3">Edit Person</h1>
            </v-col>
        </v-row>

        <v-row class="justify-center">
            <v-col class="mt-16" cols="4">

                <!-- Start of Form & Text Fields -->
                <v-form ref="form" model="valid" lazy-validation>
                    <v-checkbox class="justify-contents-right" v-model="checkbox" :label="`Archive`"></v-checkbox>
                    <v-text-field v-model="first_name" :rules="nameRules" label="First Name"></v-text-field>
                    <v-text-field v-model="last_name" :rules="nameRules" label="Last Name" required></v-text-field>
                    <v-text-field v-model="address_one" label="Address One" required></v-text-field>
                    <v-text-field v-model="address_two" label="Address Two"></v-text-field>
                    <v-text-field v-model="city" label="City" required></v-text-field>
                    <v-text-field v-model="state" label="State" required></v-text-field>
                    <v-text-field v-model="zip" label="Zip Code" required></v-text-field>
                    <v-text-field v-model="phone" label="Phone Number" required></v-text-field>
                    <v-text-field v-model="file_number" label="File Number" required></v-text-field>
                    <v-text-field v-model="association_id" label="association_id" required></v-text-field>
                    <v-text-field v-model="total" label="Total"></v-text-field>
                    <v-text-field v-model="interest" label="Interest"></v-text-field>
                    <v-text-field v-model="fees" label="Fees"></v-text-field>
                    <v-text-field v-model="principal" label="Principal"></v-text-field>
                    <v-textarea v-model="notes" label="Notes"></v-textarea>

                    <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Submit</v-btn>

                    <!-- Snackbar (activates when user clicks "SUBMIT" button) -->
                    <v-snackbar v-model="snackbar">
                        Successfully edited {{ personName }}!

                        <template v-slot:action="{ attrs }">
                            <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
                        </template>
                    </v-snackbar>

                    <!-- Button to clear the entire form (based on method defined in script tag) -->
                    <v-btn color="error" class="mr-4" @click="reset">Clear Form</v-btn>

                </v-form>
            </v-col> 
        </v-row>
    </v-container>
</template>

<script>
    import PeopleService from '../../services/PeopleService'
    export default {
        name: 'personEdit',

        data () {
            return {
                valid: true,
                checkbox: false,
                archived: this.checkbox,
                first_name: '',
                last_name: '',
                address_one: '',
                address_two: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                file_number: '',
                notes: '',
                association_id: '',
                total: '',
                interest: '',
                fees: '',
                principal: '',
                snackbar: false,
                personName: '',
                date: new Date().toISOString().slice(0, 19).replace('T', ' '),

                // Custom rules to ensure user input is valid
                nameRules: [
                    v => !!v || 'Name is required'
                ],
                interestRules: [
                    v => !!v || 'Interest is required',
                    v => /[0-9]*\.[0-9]{2,2}$/.test(v) || 'Must be in the form of a two-digit decimal.',
                ]
            }
        },

        async mounted() {
            this.id = this.$store.state.route.params.person_id
            this.entry = (await PeopleService.getPersonByID({person_id: this.id})).data
            this.checkbox = this.entry.archived
            this.first_name = this.entry.first_name
            this.last_name = this.entry.last_name
            this.address_one = this.entry.address_one
            this.address_two = this.entry.address_two
            this.city = this.entry.city
            this.state = this.entry.state
            this.zip = this.entry.zip
            this.phone = this.entry.phone
            this.file_number = this.entry.file_number
            this.notes = this.entry.notes
            this.association_id = this.entry.association_id
            this.total = this.entry.total
            this.interest = this.entry.interest
            this.fees = this.entry.fees
            this.principal = this.entry.principal
        },

        methods: {

            async validate () {
                this.$refs.form.validate()

                try {
                    PeopleService.sendPersonEdit({
                        id: this.id,
                        archived: this.checkbox,
                        first_name: this.first_name,
                        last_name: this.last_name,
                        address_one: this.address_one,
                        address_two: this.address_two,
                        city: this.city,
                        state: this.state,
                        zip: this.zip,
                        phone: this.phone,
                        file_number: this.file_number,
                        notes: this.notes,
                        association_id: this.association_id,
                        updated_at: this.date,
                        total: this.total,
                        interest: this.interest,
                        fees: this.fees,
                        principal: this.principal,
                    })

                } catch (error) {
                    this.error = error.response.data.error
                } finally {
                    this.$router.go(-1)
                }
            },

            // Clears user input within form
            reset () {
                this.$refs.form.reset()
            }
        }

    }
</script>